<template>
	<div>
		<!-- List of Prescription -->
		<template v-if="count > 0">
			<b-row>
				<b-card-group class="col-sm-6 col-lg-4 col-xl-3" v-for="userPrescription in userPrescriptions" :key="userPrescription.id">
					<store-select-prescription-card :userPrescription="userPrescription" class="mt-2" />
				</b-card-group>
			</b-row>
		</template>
		<!-- No Prescription -->
		<template v-else>
			<b-alert variant="info" class="text-center mt-3" show>
				<h4 class="alert-heading my-2">No prescriptions found!</h4>
			</b-alert>
			<hr />
		</template>

		<!-- Add Prescription Button -->
		<div class="text-center mt-4">
			<b-button @click="onCreate()" variant="primary">
				Create Prescription
			</b-button>
		</div>

		<!-- Modal to show Prescription Form -->
		<b-modal ref="userPrescriptionFormMoal" title="Add Prescription" size="lg" @close="onCancel" hide-footer no-close-on-backdrop centered>
			<user-prescription-form-component @success="onSuccess" @cancel="onCancel" />
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StoreSelectPrescriptionCard from "./StoreSelectPrescriptionCard.vue";
import UserPrescriptionFormComponent from "./UserPrescriptionFormComponent.vue";

export default {
	components: { UserPrescriptionFormComponent, StoreSelectPrescriptionCard },
	created() {
		this.getPrescriptionsApi();
		this.getCartPrescriptionsApi();
	},
	computed: {
		...mapGetters({
			userPrescriptions: "user/userPrescriptions/getPrescriptions",
		}),
		count() {
			return this.userPrescriptions.length;
		},
	},
	methods: {
		...mapActions({
			getPrescriptionsApi: "user/userPrescriptions/getPrescriptions",
			getCartPrescriptionsApi: "user/userCartPrescriptions/getCartPrescriptions",
			addCartPrescriptionApi: "user/userCartPrescriptions/addCartPrescription",
		}),
		onCreate() {
			this.$refs.userPrescriptionFormMoal.show();
		},
		onSuccess(prescriptionAdded) {
			this.$refs.userPrescriptionFormMoal.hide();
			this.addCartPrescriptionApi(prescriptionAdded.id);
		},
		onCancel() {
			this.$refs.userPrescriptionFormMoal.hide();
		},
	},
};
</script>
