<template>
  <b-row v-if="store" class="mt-4">
    <b-col class="mx-2">
      <!-- Store Details -->
      <DashboardStoreDetails v-if="store" :store="store" />

      <!-- Products List -->
      <StoreProductsList v-if="store" :store="store" />
    </b-col>
  </b-row>
</template>

<script>
import DashboardStoreDetails from "../../../components/store/DashboardStoreDetails";
import StoreProductsList from "../../../components/products/store/StoreProductsList";

export default {
  components: { DashboardStoreDetails, StoreProductsList },
  props: ["data"],
  data() {
    return {
      store: this.data,
    };
  },
};
</script>
