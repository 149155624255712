<template>
	<b-button v-if="!isCartItem || cartQuantity <= 0" @click="onClickAddItemToCart" size="sm">
		Add
	</b-button>
	<b-form-spinbutton v-else @change="onChangeQuantity" :value="cartQuantity" size="sm" min="0" :step="quantitySpinData(unit).increment" :max="quantitySpinData(unit).max" inline />
</template>

<script>
import { quantitySpinData } from "../../../app-functions";
import { mapActions, mapGetters } from "vuex";

import { addItemToCart, updateItemQuantity, deleteItemFromCart } from "../../../apis/user";

export default {
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			store: "guest/store/getStore",
			storeUrlName: "guest/store/getStoreUrlName",

			// User carts
			getCartItemOfProduct: "user/cartItems/getCartItemOfProduct",
			getCartId: "user/cart/getCartId",

			// Guest carts
			guestCartItems: "guest/carts/getCartItems",
		}),
		cartId() {
			return this.getCartId(this.storeUrlName);
		},
		cartItem() {
			return this.getCartItemOfProduct(this.product);
		},
		// Cart
		isCartItem() {
			if (this.token) {
				return Boolean(this.cartItem && this.cartItem.id);
			} else {
				return Boolean(this.guestProduct);
			}
		},
		cartQuantity() {
			if (this.token) {
				if (this.isCartItem) return this.cartItem.quantity;
			} else {
				// Get cartItem for product
				const item = this.guestProduct;
				if (item) return item.quantity;
			}
			return 0;
		},
		unit() {
			return this.product.unit;
		},

		// Guest Product
		guestProduct() {
			const items = this.guestCartItems;
			if (!items || items.length == 0) return null;
			return [...items].find((item) => item.product && item.product.id == this.product.id);
		},
	},
	methods: {
		quantitySpinData,
		...mapActions({
			// User cart items
			addCartItem: "user/cartItems/addItem",
			removeCartItem: "user/cartItems/removeItem",

			// Guest cart items
			addProductToCart: "guest/carts/addProductToCart",
			updateGuestCartItem: "guest/carts/updateCartItem",
			deleteProductFromCart: "guest/carts/deleteProductFromCart",
		}),
		onClickAddItemToCart() {
			// adding new item to user cart if logged in
			const quantity = quantitySpinData(this.unit).initial;
			if (this.token) {
				addItemToCart(this.storeUrlName, this.product.id, quantity).then((data) => {
					this.addCartItem(data).then(this.cartUpdated);
				});
			} else {
				this.addProductToCart({ product: this.product, quantity }).then(this.cartUpdated);
			}
		},
		onChangeQuantity(quantity) {
			if (quantity == 0) {
				if (this.token) {
					// make request to remove item from cart
					deleteItemFromCart(this.storeUrlName, this.cartItem.id).then(() => {
						this.removeCartItem({ id: this.cartItem.id }).then(this.cartUpdated);
					});
				} else {
					// Remove cartItem from guestCart
					const productId = this.product.id;
					this.deleteProductFromCart({ productId }).then(this.cartUpdated);
				}
			} else {
				// updating only quantity
				if (this.token) {
					updateItemQuantity(this.storeUrlName, this.cartItem.id, quantity).then((data) => {
						this.addCartItem(data).then(this.cartUpdated);
					});
				} else {
					this.updateGuestCartItem({ product: this.product, quantity }).then(this.cartUpdated);
				}
			}
		},
		cartUpdated() {
			this.$emit("cartUpdated");
		},
	},
};
</script>

<style></style>
