<template>
	<b-card :title="userPrescription.name" body-class="d-flex flex-column">
		<!-- Prescription Image -->
		<prescription-image :name="userPrescription.name" :imageUrl="userPrescription.prescriptionImage.blobUrl" />

		<b-button :disabled="busyState" @click.prevent="onSelect" :variant="selected ? `success` : `danger`" class="mt-2">
			<template v-if="selected">
				Selected
			</template>
			<template v-else>
				Select
			</template>
		</b-button>
	</b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PrescriptionImage from "./PrescriptionImage";

export default {
	components: { PrescriptionImage },
	props: ["userPrescription"],
	data() {
		return {
			busyState: false,
		};
	},
	computed: {
		...mapGetters({
			cartPrescriptions: "user/userCartPrescriptions/getCartPrescriptions",
		}),
		selectedCartPrescription() {
			// check if user prescription is a cartPrescription
			return [...this.cartPrescriptions].find((cartPrescription) => {
				if (!cartPrescription || !cartPrescription.id) return false;
				const userPrescription = cartPrescription.userPrescription;
				if (!userPrescription) return false;
				return userPrescription.id && userPrescription.id == this.userPrescription.id;
			});
		},
		selected() {
			return Boolean(this.selectedCartPrescription);
		},
	},
	methods: {
		...mapActions({
			addCartPrescription: "user/userCartPrescriptions/addCartPrescription",
			deleteCartPrescription: "user/userCartPrescriptions/deleteCartPrescription",
		}),
		async onSelect() {
			this.busyState = true;
			try {
				const selectedCartPrescription = this.selectedCartPrescription;
				if (selectedCartPrescription) {
					await this.deleteCartPrescription(selectedCartPrescription.id);
				} else {
					await this.addCartPrescription(this.userPrescription.id);
				}
			} finally {
				this.busyState = false;
			}

			this.$emit("selected");
		},
	},
};
</script>
