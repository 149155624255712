<template>
  <div class="overflow-auto" style="max-height:310px">
    <div
      v-for="(productImageSrc, index) in productImageSrcs"
      :key="index"
      class="mb-2"
    >
      <b-card
        class="ml-2 image-card"
        :bodyClass="[{ 'selected-image': selectedIndex == index }, 'p-0']"
        @click="onImageClick(index, productImageSrc)"
      >
        <b-img :src="productImageSrc" style="height: 100%" fluid center />
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productImageSrcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  methods: {
    onImageClick(index, productImageSrc) {
      // change the selected index
      this.selectedIndex = index;
      // emit listener to inform parent, that the image is changed
      this.$emit("imageSelected", { index, productImageSrc });
    },
  },
};
</script>

<style scoped>
.image-card {
  height: 55px;
}
.selected-image {
  border: 2px solid blue;
}
</style>
