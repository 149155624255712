<template>
  <product-card
    :imageSrc="imageSrc"
    :addImageClick="true"
    @click="onImageClick"
  >
    <!-- Title -->
    <p class="store-product-title small m-0 p-0">
      {{ title }}
    </p>

    <!-- Product Price & Unit -->
    <product-price-and-unit
      v-if="Boolean(store)"
      :product="product"
      :store="store"
    />

    <!-- Product Spin Button -->
    <product-spin-button :product="product" :key="spinnerKey" />

    <!-- Modal for Detailed Product View -->
    <b-modal
      :title="title"
      v-model="modalShow"
      hide-footer
      no-close-on-backdrop
      centered
    >
      <store-detailed-product
        :product="product"
        :cartItem="cartItem"
        @cartUpdated="spinnerKey++"
      />
    </b-modal>
  </product-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ProductCard from "../../common/ProductCard.vue";
import StoreDetailedProduct from "./StoreDetailedProduct.vue";
import ProductSpinButton from "./ProductSpinButton.vue";
import ProductPriceAndUnit from "../ProductPriceAndUnit.vue";

export default {
  components: {
    ProductCard,
    StoreDetailedProduct,
    ProductSpinButton,
    ProductPriceAndUnit,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    cartItem: {
      type: Object,
    },
  },
  created() {
    // request for images
    this.getGuestProductImages({
      storeUrlName: this.storeUrlName,
      productId: this.product.id,
    }).then((images) => (this.productImages = [...images]));
  },
  data() {
    return {
      // modal for detailed product
      modalShow: false,
      // force update spinner
      spinnerKey: 0,

      // product images
      productImages: [],

      title: this.product.name,
      unit: this.product.unit,
      price: this.product.price,
    };
  },
  computed: {
    ...mapGetters({
      store: "guest/store/getStore",
      storeUrlName: "guest/store/getStoreUrlName",
    }),
    imageSrc() {
      const productImages = this.productImages;
      if (productImages.length > 0) {
        return productImages[0].src;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      getGuestProductImages: "productImages/getGuestProductImages",
    }),
    onImageClick() {
      this.modalShow = !this.modalShow;
    },
  },
};
</script>
