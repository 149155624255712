<template>
  <!-- Search Input with Button -->
  <b-row no-gutters>
    <b-col col sm="4">
      <!-- Categories -->
      <b-form-select
        v-model="form.categoryId"
        :options="categories"
        value-field="id"
        text-field="name"
        size="sm"
        @change="onCategoryChange"
      >
        <!-- This slot appears above the options from 'options' prop -->
        <template #first>
          <b-form-select-option :value="null">
            -- All --
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-col>

    <b-col col sm="8">
      <!-- Input -->
      <b-form-input
        type="search"
        id="name"
        v-model="form.name"
        placeholder="Search Products"
        @input="onInputChange"
        size="sm"
        trim
      />
    </b-col>
  </b-row>
</template>

<script>
import { getCategories } from "../../../apis/guest";

export default {
  props: ["store"],
  created() {
    this.initCategories();
  },
  data() {
    return {
      form: {
        categoryId: null,
        name: null,
      },
      categories: [],
    };
  },
  methods: {
    onCategoryChange() {
      this.form.name = "";
      this.emit();
    },
    onInputChange() {
      this.emit();
    },
    emit() {
      this.$emit("input", this.form);
    },
    initCategories() {
      if (this.store) {
        getCategories(this.store.storeUrlName).then((data) => {
          this.categories = data;
        });
      }
    },
  },
};
</script>
