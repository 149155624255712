<template>
	<div class="mt-4">
		<!-- Adding Search Product Option -->
		<StoreProductSearch :store="store" @input="onSearchChanged" />

		<b-row class="mt-3">
			<!-- Show Add Prescription To Cart -->
			<add-prescription-to-cart-button />

			<b-col cols="6" sm="4" md="3" lg="2" v-for="product in products" :key="product.id">
				<StoreProduct :product="product" :cartItem="getCartItemOfProduct(product)" class="mb-3" />
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<MarketPrice class="small mt-3" />
			</b-col>
		</b-row>

		<!-- Pagination -->
		<div>
			<b-pagination v-model="currentPage" :total-rows="count" :per-page="perPageCount" @change="getPaginatedProducts" align="center"></b-pagination>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getProducts, getProductsCount } from "../../../apis/guest";

import StoreProduct from "../../../components/products/store/StoreProduct";
import StoreProductSearch from "../../../components/products/store/StoreProductSearch";
import MarketPrice from "../../common/MarketPrice.vue";
import AddPrescriptionToCartButton from "./AddPrescriptionToCartButton";

export default {
	components: {
		StoreProduct,
		StoreProductSearch,
		MarketPrice,
		AddPrescriptionToCartButton,
	},
	props: ["store"],
	created() {
		this.initProducts();
	},
	data() {
		return {
			categoryId: null,
			productName: null,

			// for pagination
			currentPage: 1,
			perPageCount: 20,
			count: 0,
		};
	},
	computed: {
		...mapGetters({
			products: "guest/products/getList",
			getCartItemOfProduct: "user/cartItems/getCartItemOfProduct",
		}),
		storeUrlName() {
			return this.store.storeUrlName;
		},
		apiParams() {
			return {
				categoryId: this.categoryId,
				name: this.productName,
				page: this.currentPage - 1,
				size: this.perPageCount,
				// sort: "id,desc", // for sorting by id desc
			};
		},
	},
	methods: {
		...mapActions({
			setProducts: "guest/products/setList",
		}),
		initProducts() {
			this.makeProductsRequest();
		},
		onSearchChanged({ categoryId, name }) {
			this.categoryId = categoryId;
			this.productName = name;
			this.makeProductsRequest();
		},
		makeProductsRequest() {
			getProductsCount(this.storeUrlName, this.apiParams).then((data) => {
				this.count = data.count;
				this.getPaginatedProducts(this.currentPage);
			});
		},
		getPaginatedProducts(page) {
			// first set the page
			this.currentPage = page;

			getProducts(this.storeUrlName, this.apiParams).then((data) => {
				this.setProducts(data);
			});
		},
	},
};
</script>
