<template>
	<b-col v-if="enablePrescription" cols="6" sm="4" md="3" lg="2" class="d-flex justify-content-center align-items-start text-center">
		<b-button @click="onAddPrescription()" size="sm" variant="outline-primary">
			<b-icon-plus-circle scale="1.5" class="my-2" />
			<div>Select Prescription</div>
		</b-button>

		<!-- Display Not Logged in message. -->
		<b-modal ref="notLoggedInModal" title="Login?" @ok="performLogin()" no-close-on-backdrop>
			You need to be logged in to add a prescription. Continue to login?
		</b-modal>

		<!-- Select Prescriptions Modal (Only if Logged In) -->
		<b-modal ref="selectPrescriptionModal" title="Select Prescription" size="lg" hide-footer no-close-on-backdrop centered>
			<store-select-prescription-modal />
		</b-modal>
	</b-col>
</template>

<script>
import { mapGetters } from "vuex";
import StoreSelectPrescriptionModal from "../../prescriptions/StoreSelectPrescriptionModal";
export default {
	components: {
		StoreSelectPrescriptionModal,
	},
	computed: {
		...mapGetters({
			enablePrescription: "getEnablePrescription",
			token: "login/getToken",
			store: "guest/store/getStore",
		}),
		storeUrlName() {
			return this.store.storeUrlName;
		},
	},
	methods: {
		onAddPrescription() {
			if (!this.token) {
				this.$refs.notLoggedInModal.show();
				return;
			}
			this.$refs.selectPrescriptionModal.show();
		},
		performLogin() {
			this.$router.push({
				name: "CheckUserCredentials",
				params: this.$route.params,
				query: { redirect: "StoreDashboard", storeUrlName: this.storeUrlName },
			});
		},
	},
};
</script>

<style></style>
