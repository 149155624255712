<template>
  <b-row>
    <b-col v-if="hasImages" col cols="2" class="p-0">
      <store-product-sidebar-images
        :productImageSrcs="productImageSrcs"
        @imageSelected="changeSelectedImageSrc"
      />
    </b-col>

    <b-col
      col
      :offset="hasImages ? 0 : 1"
      :cols="10"
      :offset-md="hasImages ? 1 : 2"
      :md="8"
    >
      <product-card :imageSrc="selectedImageSrc">
        <!-- Title -->
        <p class="store-product-title small m-0 p-0">
          {{ title }}
        </p>

        <!-- Price & Unit -->
        <p class="store-price-unit small m-0 p-0 mb-1">
          <template v-if="price">
            {{ price.toString() | rupeeCurrency }} - {{ unit }}
          </template>
          <template v-else> {{ "**" | rupeeCurrency }} - {{ unit }} </template>
        </p>

        <!-- Product Description -->
        <p v-if="product.description" class="small mb-1">
          {{ product.description }}
        </p>

        <!-- Product Spin Button -->
        <product-spin-button
          :product="product"
          @cartUpdated="$emit('cartUpdated')"
        />
      </product-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import ProductCard from "../../common/ProductCard.vue";
import StoreProductSidebarImages from "./StoreProductSidebarImages.vue";
import ProductSpinButton from "./ProductSpinButton.vue";

export default {
  created() {
    this.productImageSrcs = [...this.getProductImages(this.product.id)].map(
      (image) => image.src
    );
    if (this.hasImages) {
      this.selectedImageSrc = this.productImageSrcs[0];
    }
  },
  components: { ProductCard, StoreProductSidebarImages, ProductSpinButton },
  props: {
    product: {
      type: Object,
      required: true,
    },
    cartItem: {
      type: Object,
    },
  },
  data() {
    return {
      title: this.product.name,
      unit: this.product.unit,
      price: this.product.price,

      selectedImageSrc: null,
      productImageSrcs: [],
    };
  },
  computed: {
    ...mapGetters({
      getProductImages: "productImages/getProductImages",
    }),
    hasImages() {
      return this.productImageSrcs.length > 0;
    },
  },
  methods: {
    changeSelectedImageSrc(data) {
      this.selectedImageSrc = data.productImageSrc;
    },
  },
};
</script>
